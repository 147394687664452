<template>
  <div class="style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="6">
          <div class="title-header">
            Gestion des configurations Mensuelles
          </div>
        </v-col>
        <v-col cols="1">
          <v-progress-circular
            class="ml-2"
            v-if="getConfLoading"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="4">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getConfErrors">
              <ul v-if="Array.isArray(getConfErrors)" class="mb-0">
                <li v-for="(e, index) in getConfErrors" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getConfErrors }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter">
          <span v-if="filtre.period" class="mr-4"
            ><b>Période :</b> {{ filtre.period.text }}</span
          >
          <span v-if="filtre.type_produit">
            <b>Type : </b>{{ filtre.type_produit.text }}</span
          >
        </div>
        <div class="left-block-filter">
          <v-tooltip
            bottom
            color="#311B92"
            v-if="checkPermission('GCMACM')"
            v-show="
              selectedMenu == 'client' ||
                selectedMenu == 'bien' ||
                selectedMenu == 'service'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mb-1 mr-1"
                v-on="on"
                v-bind="attrs"
                @click.prevent="handleModalConfig('show')"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter une configuration</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                size="sm"
                v-on="on"
                v-bind="attrs"
                @click="menu = true"
                class="block-icon mr-2 button-height-unset mb-1"
              >
                <IconFiltre></IconFiltre>
              </div>
            </template>
            <span>Choisir un Filtre</span>
          </v-tooltip>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="350"
            origin="top right"
            content-class="popover-menu-configuration"
          >
            <v-card>
              <v-divider class="line-hr-filter"></v-divider>
              <v-card-text class="body-card filter-content-popup">
                <v-form>
                  <!-- SOCIETE -->
                  <v-autocomplete
                    color="#704ad1"
                    item-color="#704ad1"
                    v-model="filtre.vendeur"
                    :items="computedGetVendeurs"
                    @change="handleFilter"
                    placeholder="Séléctionnez"
                    :persistent-placeholder="true"
                    clearable
                    dense
                    class="mb-1"
                    label="Société"
                    item-text="text"
                    multiple
                    item-value="value"
                    :auto-focus="false"
                    return-object
                    no-data-text="Aucune société trouvée"
                    outlined
                  ></v-autocomplete>
                  <!-- CLIENT -->
                  <v-autocomplete
                    color="#704ad1"
                    item-color="#704ad1"
                    v-model="filtre.acheteur"
                    :items="computedGetAcheteurs"
                    @change="handleFilter"
                    placeholder="Séléctionnez"
                    :persistent-placeholder="true"
                    multiple
                    clearable
                    dense
                    class="mb-1"
                    label="Client"
                    item-text="text"
                    return-object
                    item-value="value"
                    :auto-focus="false"
                    no-data-text="Aucun client trouvée"
                    outlined
                  ></v-autocomplete>
                  <!-- PERIODE -->
                  <v-autocomplete
                    color="#704ad1"
                    item-color="#704ad1"
                    v-model="filtre.period"
                    :items="periodOptions"
                    @change="handleFilter"
                    placeholder="Séléctionnez"
                    :persistent-placeholder="true"
                    return-object
                    label="Période"
                    class="mb-2"
                    item-text="text"
                    item-value="value"
                    :auto-focus="false"
                    no-data-text="Aucun période trouvée"
                    outlined
                    dense
                    clearable
                  ></v-autocomplete>
                  <!-- TYPE PRODUIT -->
                  <v-autocomplete
                    color="#704ad1"
                    item-color="#704ad1"
                    v-model="filtre.type_produit"
                    :items="typeProduitOptions"
                    @change="handleFilter"
                    placeholder="Séléctionnez"
                    :persistent-placeholder="true"
                    label="Type"
                    clearable
                    dense
                    return-object
                    item-text="text"
                    item-value="value"
                    class="mb-1"
                    :auto-focus="false"
                    no-data-text="Aucun type trouvée"
                    outlined
                  ></v-autocomplete>
                  <!-- SERVICE BIEN -->
                  <v-autocomplete
                    v-if="
                      filtre.type_produit != null && filtre.type_produit.value
                    "
                    color="#704ad1"
                    item-color="#704ad1"
                    v-model="filtre.service_bien"
                    :items="
                      filtre.type_produit.value == 'bien' ? biens : services
                    "
                    @change="handleFilter"
                    placeholder="Séléctionnez"
                    multiple
                    :persistent-placeholder="true"
                    return-object
                    class="mb-1"
                    :label="
                      filtre.type_produit.value == 'bien' ? 'Bien' : 'Service'
                    "
                    item-text="nom"
                    item-value="id"
                    clearable
                    :auto-focus="false"
                    :no-data-text="
                      filtre.type_produit.value == 'bien'
                        ? 'Aucun Bien trouvé'
                        : 'Aucun Service trouvé'
                    "
                    outlined
                  ></v-autocomplete>
                </v-form>
              </v-card-text>
              <v-card-actions class="popover-action-filter">
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div class="menu-facture-frais mt-1">
        <div class="list-tabs">
          <v-tabs
            bg-color="#704ad1"
            :hide-slider="false"
            slider-color="#704ad1"
            v-model="model"
          >
            <v-tab
              :href="`#tab-${0}`"
              @click="clickMenu('client')"
              v-if="checkPermission('GCM')"
            >
              <router-link
                to="/setting/configurationMensuel"
                class="font-tabs-facture"
              >
                Configurations par sociétés
              </router-link>
            </v-tab>
            <v-tab
              :href="`#tab-${1}`"
              @click="clickMenu('bien')"
              v-if="checkPermission('GCMCPB')"
            >
              <router-link
                to="/setting/configurationMensuel/gestion-configuration-bien-mensuel"
                class="font-tabs-facture"
              >
                Configurations par Biens
              </router-link>
            </v-tab>
            <v-tab
              :href="`#tab-${2}`"
              @click="clickMenu('service')"
              v-if="checkPermission('GCMCPS')"
            >
              <router-link
                to="/setting/configurationMensuel/gestion-configuration-service-mensuel"
                class="font-tabs-facture"
              >
                Configurations par services
              </router-link>
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <div>
        <v-row class="mb-2">
          <!-- SOCIETE -->
          <v-col v-if="filtre.vendeur.length" :cols="computedLengthFilter">
            <span class="title-block-chip-filter ml-3">Société : </span>
            <v-sheet elevation="1" class="py-0 px-1 ml-3">
              <v-chip-group mandatory>
                <v-chip
                  :key="v.text + 'societe'"
                  @click:close="remove(v, 'vendeur', 'handleFilter')"
                  v-for="v in filtre.vendeur"
                  close
                  x-small
                  color="#f1edfb"
                  text-color="rgb(112, 74, 209)"
                >
                  {{ v.text }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </v-col>
          <v-col v-if="filtre.acheteur.length" :cols="computedLengthFilter">
            <span class="title-block-chip-filter ml-3">Client : </span>
            <v-sheet elevation="1" class="py-0 px-1 ml-3">
              <v-chip-group mandatory>
                <v-chip
                  :key="v.text + 'client'"
                  @click:close="remove(v, 'acheteur', 'handleFilter')"
                  v-for="v in filtre.acheteur"
                  close
                  x-small
                  color="#f1edfb"
                  text-color="rgb(112, 74, 209)"
                >
                  {{ v.text }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </v-col>

          <v-col
            v-if="filtre.service_bien.length && filtre.type_produit"
            :cols="computedLengthFilter"
          >
            <span class="title-block-chip-filter ml-3">{{
              filtre.type_produit.value == 'bien' ? 'Bien' : 'Service'
            }}</span>
            <v-sheet elevation="1" class="py-0 px-1 ml-3">
              <v-chip-group mandatory>
                <v-chip
                  :key="v.nom + 'service'"
                  @click:close="remove(v, 'service_bien', 'handleFilter')"
                  v-for="v in filtre.service_bien"
                  close
                  x-small
                  color="#f1edfb"
                  text-color="rgb(112, 74, 209)"
                >
                  {{ v.nom }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </v-col>
        </v-row>
        <router-view></router-view>
      </div>
    </div>
    <v-dialog
      v-model="modalConfig"
      persistent
      max-width="1500px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="v-dialog.custom-vuetify-dialog-list"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter une configuration </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalConfig('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="ListType"
                  class="select-menu"
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="configurationToAdd.type"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  @change="handleErrorConfig"
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToAdd.service_bien"
                  :items="computedListConfig"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  @change="handleErrorConfig"
                  :disabled="disable"
                  label="Type de configuration *"
                  item-text="text"
                  item-value="value"
                  :auto-focus="false"
                  no-data-text="Aucune configuration trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedListJourCreation"
                  class="select-menu"
                  label="Jour de création"
                  :persistent-placeholder="true"
                  v-model="configurationToAdd.jour_creation"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToAdd.vendeur_id"
                  :items="
                    getSettingFilialesTh.filter(
                      item => item.type != 'particulier'
                    )
                  "
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  @change="handleErrorConfig"
                  label="Société *"
                  item-text="name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToAdd.acheteur_id"
                  :items="
                    getSettingFilialesTh.filter(
                      item => item.type != 'particulier'
                    )
                  "
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  @change="handleErrorConfig"
                  label="Client *"
                  item-text="name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucun client trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Montant HT"
                  :persistent-placeholder="true"
                  v-model="configurationToAdd.montant"
                  @input="calculTTC"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-row>
                  <v-col cols="9">
                    <v-select
                      placeholder="Séléctionnez"
                      :items="getAllTvas"
                      class="select-menu"
                      label="TVA %"
                      :persistent-placeholder="true"
                      v-model="configurationToAdd.tva"
                      @input="calculTTC"
                      item-text="valeur"
                      item-value="valeur"
                      outlined
                      dense
                      :auto-focus="false"
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#704ad1"
                      item-color="#704ad1"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="3">
                    <div
                      class="btn-add-tva"
                      small
                      @click.stop.prevent="ModalAddTva('show')"
                    >
                      <font-awesome-icon icon="plus" />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Montant TTC"
                  :persistent-placeholder="true"
                  v-model="montant_ttc"
                  :disabled="true"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-menu :close-on-content-click="true" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="configurationToAdd.date_debut"
                      label="Date début *"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      required
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      :autofocus="false"
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                    v-model="configurationToAdd.date_debut"
                    @change="handleErrorConfig"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu :close-on-content-click="true" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="configurationToAdd.date_fin"
                      label="Date fin *"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      required
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      :autofocus="false"
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                    v-model="configurationToAdd.date_fin"
                    @change="handleErrorConfig"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="freqOptions"
                  class="select-menu"
                  label="Fréquence"
                  :persistent-placeholder="true"
                  v-model="configurationToAdd.frequence"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Objet"
                  v-model="configurationToAdd.objet"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="configurationToAdd.description"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
          </form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
          <div v-if="errorDuplicate" class="message-error-modal">
            <ul v-if="Array.isArray(errorDuplicate)" class="mb-0">
              <li v-for="(e, index) in errorDuplicate" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ errorDuplicate }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleSubmitConfiguration"
            :loading="loading"
            :disabled="validateToAddconf"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalConfig('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL TVA-->
    <v-dialog
      v-model="modalAddTva"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter TVA </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="ModalAddTva('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Tva *"
                  v-model="valeur"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col clos="6">
                <v-text-field
                  label="Compte tva vente"
                  v-model="compte_vente"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col clos="6">
                <v-text-field
                  label="Compte tva achat"
                  v-model="compte_achat"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col clos="6">
                <v-text-field
                  label="compte marchandise(HT)"
                  v-model="compte_marchandise"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col clos="6">
                <v-text-field
                  label="Compte prestation(HT) "
                  v-model="compte_prestation"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="margin-none">
              <v-col cols="2">
                <v-checkbox
                  label="Activer"
                  class="checked-tva margin-none"
                  color="#704ad1"
                  :value="true"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                  v-model="is_active"
                ></v-checkbox>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <div v-if="errorTvaUnite" class="message-error-modal">
          <ul v-if="Array.isArray(errorTvaUnite)" class="mb-0">
            <li v-for="(e, index) in errorTvaUnite" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ errorTvaUnite }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="addTav('tva')"
            :loading="loadingTvaUnite"
            :disabled="loadingTvaUnite"
            :class="{ loader: loadingTvaUnite }"
          >
            Ajouter
          </v-btn>
          <v-btn text @click="ModalAddTva('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      model: 0,
      menu: false,
      modalConfig: false,
      valeur: 0,
      tvaToAdd: null,
      modalAddTva: false,
      errorTvaUnite: null,
      loadingTvaUnite: false,
      selectedMenu: 'client',
      configurationToAdd: {
        type: null,
        service_bien: null,
        vendeur_id: null,
        acheteur_id: null,
        montant: 0,
        tva: 0,
        description: null,
        objet: null,
        date_debut: null,
        date_fin: null,
        frequence: 'mensuel'
      },
      filtre: {
        vendeur: [],
        acheteur: [],
        period: null,
        type_produit: null,
        service_bien: []
      },
      montant_ttc: 0,
      error: [],
      loading: false,
      disable: true,
      errorDuplicate: null,
      validateToAddconf: false,
      freqOptions: [{ text: 'Mensuel', value: 'mensuel' }],
      periodOptions: [
        { text: 'À venir', value: 'avenir' },
        { text: 'Encours', value: 'encours' },
        { text: 'Expiré', value: 'expiree' }
      ],
      typeProduitOptions: [
        { text: 'Bien', value: 'bien' },
        { text: 'Service', value: 'Service' }
      ],
      compte_vente: null,
      compte_achat: null,
      compte_marchandise: null,
      compte_prestation: null,
      is_active: true
    };
  },
  methods: {
    ...mapActions([
      'addNewConfigurationMensuel',
      'checkPeriodConfig',
      'getTvas',
      'getSettingFilialeTh',
      'getServices',
      'getAllBiens',
      'getFilialsOfConnectedResponsable',
      'fillFlitre',
      'addUniteTva'
    ]),
    remove(item, filter, fct) {
      this.filtre[filter].splice(this.filtre[filter].indexOf(item), 1);
      this[fct]();
    },
    async addTav(type) {
      this.errorTvaUnite = '';
      if (this.valeur > 100) {
        this.errorTvaUnite = 'tva doit etre inferieur à 100';
      } else if (this.valeur === '' || this.valeur === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: this.valeur,
          type: type,
          compte_vente: this.compte_vente,
          compte_achat: this.compte_achat,
          compte_marchandise: this.compte_marchandise,
          compte_prestation: this.compte_prestation,
          is_active: this.is_active
        });
        if (response.success) {
          this.ModalAddTva('hide');
          this.modalConfig = true;
          this.valeur = 0;
          this.loadingTvaUnite = false;
        } else {
          this.loadingTvaUnite = false;
          this.errorTvaUnite = response.response;
        }
      }
    },
    ModalAddTva(action) {
      if (action === 'show') {
        this.modalConfig = false;
        this.modalAddTva = true;
      } else if (action === 'hide') {
        this.modalAddTva = false;
        this.modalConfig = true;
        this.resteTvaUniteModal();
      }
    },
    resteTvaUniteModal() {
      this.valeur = null;
      this.errorTvaUnite = null;
      this.compte_vente = null;
      this.compte_achat = null;
      this.compte_marchandise = null;
      this.compte_prestation = null;
      this.is_active = true;
    },
    handleModalConfig(action) {
      if (action == 'show') {
        this.modalConfig = true;
      } else {
        this.modalConfig = false;
        this.resetModal();
      }
    },
    resetModalTVA() {
      this.tvaToAdd = null;
      this.errorTvaUnite = null;
    },
    handleFilter() {
      this.fillFlitre(this.filtre);
    },
    calculTTC() {
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToAdd.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToAdd.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToAdd.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
    },
    clickMenu(menu) {
      this.filtre = {
        vendeur: [],
        acheteur: [],
        period: null,
        type_produit: null,
        service_bien: []
      };
      this.handleFilter();
      this.selectedMenu = menu;
    },
    resetModal() {
      this.configurationToAdd = {
        type: null,
        service_bien: null,
        jour_creation: null,
        vendeur_id: null,
        acheteur_id: null,
        montant: 0,
        tva: 0,
        description: null,
        objet: null,
        date_debut: null,
        date_fin: null,
        frequence: 'mensuel'
      };
      this.disable = true;
      this.loading = false;
      this.error = [];
      this.errorDuplicate = null;
      this.validateToAddconf = false;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleSubmitConfiguration() {
      this.error = null;
      this.loading = true;
      const response = await this.addNewConfigurationMensuel({
        configurationToAdd: this.configurationToAdd,
        type: this.selectedMenu
      });
      if (response.success) {
        this.loading = false;
        this.handleModalConfig('hide');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async handleErrorConfig() {
      if (
        this.configurationToAdd.type != 'service' ||
        this.configurationToAdd.type != 'bien'
      ) {
        this.disable = false;
      }
      var objToSend = {
        vendeur_id: this.configurationToAdd.vendeur_id
          ? this.configurationToAdd.vendeur_id
          : null,
        acheteur_id: this.configurationToAdd.acheteur_id
          ? this.configurationToAdd.acheteur_id
          : null,
        date_debut: this.configurationToAdd.date_debut
          ? this.configurationToAdd.date_debut
          : null,
        date_fin: this.configurationToAdd.date_fin
          ? this.configurationToAdd.date_fin
          : null,
        type: this.configurationToAdd.type,
        service_bien: this.configurationToAdd.service_bien
      };
      if (objToSend.vendeur_id != null && objToSend.acheteur_id != null) {
        const response = await this.checkPeriodConfig(objToSend);
        this.validateToAddconf = true;
        if (response?.success) {
          if (response.response) {
            this.validateToAddconf = false;
            this.errorDuplicate = null;
          }
        } else {
          this.errorDuplicate = response.error;
          this.validateToAddconf = true;
        }
      }
    },
    disabledEndDate(date) {
      return (
        this.configurationToAdd.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.configurationToAdd.date_debut)
      );
    },
    disabledStartDate(date) {
      return (
        this.configurationToAdd.date_fin &&
        moment(date, 'YYYY-MM-DD') >= new Date(this.configurationToAdd.date_fin)
      );
    }
  },
  computed: {
    ...mapGetters([
      'getConfLoading',
      'checkPermission',
      'getConfErrors',
      'getAllTvas',
      'getSettingFilialesTh',
      'biens',
      'services',
      'getFiliaeOfResponsable',
      'getFiltre',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.filtre.vendeur,
        this.filtre.acheteur,
        this.filtre.service_bien
      );
    },
    computedGetAcheteurs() {
      let acheteurNames = this.getSettingFilialesTh
        .filter(item => item.type != 'particulier')
        .map(item => {
          return { value: item.id, text: item.name };
        });
      return acheteurNames;
    },
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    ListType() {
      let list = [];
      if (this.checkPermission('GBHT')) {
        list.push({ value: 'bien', text: 'Bien' });
      }
      if (this.checkPermission('GCM')) {
        list.push({ value: 'service', text: 'Service' });
      }
      return list;
    },
    computedListConfig() {
      let selected = [];
      if (
        this.configurationToAdd?.type == 'service' ||
        this.configurationToUpdate?.type == 'service'
      ) {
        return this.services.map(service => {
          return {
            value: service.id,
            text: service.nom
          };
        });
      } else if (
        this.configurationToAdd?.type == 'bien' ||
        this.configurationToUpdate?.type == 'bien'
      ) {
        return this.biens
          ?.filter(item => item.blocked == false)
          .map(bien => {
            return {
              value: bien.id,
              text: bien.nom
            };
          });
      } else {
        return selected;
      }
    },
    computedListJourCreation() {
      return [
        { text: 'Jour du création', value: null },
        { text: 'Fin du mois', value: -1 },
        { text: '01', value: 1 },
        { text: '02', value: 2 },
        { text: '03', value: 3 },
        { text: '04', value: 4 },
        { text: '05', value: 5 },
        { text: '06', value: 6 },
        { text: '07', value: 7 },
        { text: '08', value: 8 },
        { text: '09', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 },
        { text: '13', value: 13 },
        { text: '14', value: 14 },
        { text: '15', value: 15 },
        { text: '16', value: 16 },
        { text: '17', value: 17 },
        { text: '18', value: 18 },
        { text: '19', value: 19 },
        { text: '21', value: 21 },
        { text: '22', value: 22 },
        { text: '23', value: 23 },
        { text: '24', value: 24 },
        { text: '25', value: 25 },
        { text: '26', value: 26 },
        { text: '27', value: 27 },
        { text: '28', value: 28 },
        { text: '29', value: 29 },
        { text: '30', value: 30 },
        { text: '31', value: 31 }
      ];
    }
  },
  components: {
    IconFiltre: () => import('@/assets/img/IconFiltre.vue')
  },
  async mounted() {
    if (
      this.$route.path ===
      '/setting/configurationMensuel/gestion-configuration-service-mensuel'
    ) {
      this.selectedMenu = 'service';
      this.model = 'tab-2';
    }
    if (
      this.$route.path ===
      '/setting/configurationMensuel/gestion-configuration-bien-mensuel'
    ) {
      this.selectedMenu = 'bien';
      this.model = 'tab-1';
    }
    if (this.$route.path === '/setting/configurationMensuel') {
      this.selectedMenu = 'client';
      this.model = 'tab-0';
    }
    await this.getFilialsOfConnectedResponsable();
    await this.getTvas();
    await this.getSettingFilialeTh();
    await this.getServices();
    await this.getAllBiens();
    this.listTva = this.getAllTvas;
  }
};
</script>
<style lang="scss" scoped>
.v-application {
  a {
    color: #2f2f2f !important;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 5px;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;
    &.router-link-exact-active {
      color: #704ad1 !important;
      font-size: 11px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      white-space: nowrap;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      text-decoration: none;
      background-color: #f1edfb;
    }
  }
}
</style>
