<template>
  <div>
    <div class="style-box-setting index-style-user">
      <div class="style-box-setting-header">
        <v-row>
          <v-col cols="4">
            <div class="title-header">
              Gérer les utilisateurs
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <div>
        <b-button
          v-if="checkPermission('GPATADDTH')"
          v-show="selectedMenu == 'societe'"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addGestionSociete
          ><font-awesome-icon icon="plus" /> Ajouter une gestion pour
          sociéte</b-button
        >
        <b-modal
          id="addGestionSociete"
          ref="addGestionSociete"
          title="Ajouter un profil"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Nouvelle société à traiter</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addGestionSociete')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleAddGestionSociete"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom *"
                  label-for="nom"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="nom"
                    v-model="gestionSocieteToAdd.name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Sociéte"
                  label-for="filiale-responsable"
                  class="vue-select-modal-champ"
                >
                  <multiselect
                    v-model="gestionSocieteToAdd.societe"
                    :options="computedSociete"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="name"
                    track-by="id"
                    :preselect-first="false"
                    class="select-vue-component-style mb-4"
                    :showLabels="false"
                    :loading="getLoadingSettingFilialeTh"
                  >
                    <span slot="noResult">Aucune sociéte trouvé.</span>
                    <span slot="noOptions">Aucune sociéte trouvé.</span>
                  </multiselect>
                </b-form-group>
                <b-form-group
                  label="Interface"
                  label-for="filiale-responsable"
                  class="vue-select-modal-champ"
                >
                  <multiselect
                    v-model="gestionSocieteToAdd.interface"
                    :options="listInterface"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="name"
                    track-by="id"
                    :preselect-first="false"
                    class="select-vue-component-style mb-4"
                    :showLabels="false"
                  >
                    <span slot="noResult">Aucun interface trouvé.</span>
                    <span slot="noOptions">Aucun interface trouvé.</span>
                  </multiselect>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="getErrorAddGestionSocieteAtraiter" class="error">
                      <ul
                        v-if="Array.isArray(getErrorAddGestionSocieteAtraiter)"
                      >
                        <li
                          v-for="(e,
                          index) in getErrorAddGestionSocieteAtraiter"
                          :key="index"
                          style="list-style: none"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else style="list-style: none">
                        {{ getErrorAddGestionSocieteAtraiter }}
                      </div>
                    </div>
                  </div>
                  <div class="messageError" v-if="errorSocieteTraite">
                    <div class="error">
                      {{ errorSocieteTraite }}
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div
                        v-if="getLoadingAddSocieteAtraiter"
                        class="loading ml-2"
                      >
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="sub-menu-simple">
      <menu-user :itemsUser="menujUser" :model="selectedMenu"></menu-user>
      <div class="body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissionModel from '../../../models/permisionModel';
import MenuUser from './menuUser.vue';
export default {
  name: 'Gestion-des-utilisateurs',
  data() {
    return {
      selectedMenu: 'users',
      loading: false,
      listInterface: [{ name: 'Commercial', id: 'GAPC', checked: false }],
      error: null,
      gestionSocieteToAdd: {
        name: null,
        societe: [],
        interface: []
      },
      permessionToAdd: {
        permission: null,
        page: [],
        users: []
      },
      roleToAdd: {
        role: null
      },
      getListePermission: [],
      getListePermissionToUse: [],
      errorSocieteTraite: null
    };
  },
  methods: {
    ...mapActions([
      'addNewPermission',
      'getUsersWithoutPermission',
      'addNewRole',
      'getAllRoles',
      'getSettingFilialeTh',
      'addNewSocieteAtraiter',
      'resetDataSocieteAtraiter',
      'getAllSocieteAtraiters'
    ]),

    clickMenu(menu) {
      this.selectedMenu = menu;
    },
    handleAddGestionSociete() {
      this.errorSocieteTraite = null;
      if (
        this.gestionSocieteToAdd.societe.length == 0 ||
        this.gestionSocieteToAdd.interface.length == 0
      ) {
        this.errorSocieteTraite =
          this.gestionSocieteToAdd.societe.length == 0
            ? 'sélectionner au moins une sociéte'
            : 'Sélectionner au moins un interface';
      } else {
        let interfaces = [];
        this.listInterface.forEach(item => {
          this.gestionSocieteToAdd.interface.forEach(element => {
            if (element.key == item.key) {
              item.checked = true;
            }
          });
          interfaces.push(item);
        });
        let societe = [];
        this.computedSociete.forEach(item => {
          this.gestionSocieteToAdd.societe.forEach(element => {
            if (element.id == item.id) {
              item.checked = true;
            }
          });
          societe.push(item);
        });
        const response = this.addNewSocieteAtraiter({
          name: this.gestionSocieteToAdd.name,
          societe: societe,
          interface: interfaces
        });
        if (response) {
          this.hideModal('addGestionSociete');
        }
      }
    },
    async handleAddRole() {
      let role = {
        name: this.roleToAdd.role
      };
      const response = await this.addNewRole(role);
      if (response == true) {
        this.resetModal();
        this.$refs['addRoleModal'].hide();
      }
    },
    resetModal() {
      this.error = null;
      this.permessionToAdd = {
        permission: null,
        page: [],
        users: []
      };
      this.roleToAdd = {
        name: null
      };
      this.gestionSocieteToAdd = {
        name: null,
        societe: [],
        interface: []
      };
      this.resetDataSocieteAtraiter();
      this.getListePermission = permissionModel.create().list;
      this.getListePermissionToUse = permissionModel.create().list;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async hendleSubmitPermission() {
      this.loading = true;
      this.error = null;
      if (this.permessionToAdd.users.length == 0) {
        this.loading = false;
        this.error = [
          {
            Utilisateur: 'user required'
          }
        ];
        return;
      }
      if (this.permessionToAdd.page.length == 0) {
        this.loading = false;
        this.error = [
          {
            interface: 'interface required'
          }
        ];
        return;
      }
      const response = await this.addNewPermission(this.permessionToAdd);
      if (response.succes) {
        this.loading = false;
        this.hideModal('addUserPermission');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    handleClickModalAddUserPermission() {
      this.getUsersWithoutPermission();
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'loadingAdd',
      'errorAddRole',
      'getUserData',
      'users',
      'usersWithoutPermission',
      'loadingUserWithoutPermission',
      'roles',
      'getSettingFilialesTh',
      'getLoadingSettingFilialeTh',
      'getLoadingAddSocieteAtraiter',
      'getErrorAddGestionSocieteAtraiter',
      'getGestionSocieteAtraiter'
    ]),
    menujUser() {
      let menu = [
        {
          name: 'Utilisateurs',
          href: '/setting/users',
          permission: this.checkPermission('GUTH')
        },
        {
          name: 'Gestion des permissions',
          href: '/setting/users/permissions',
          permission: this.checkPermission('GPHT')
        },
        {
          name: 'Gestion des profils',
          href: '/setting/users/profils',
          permission: this.checkPermission('GPRHT')
        },
        {
          name: 'Gestion des familles',
          href: '/setting/users/familles',
          permission: this.checkPermission('GPHTF')
        },
        {
          name: 'Gestion des Sociéte à traiter',
          href: '/setting/users/gestion-societe-permission',
          permission: this.checkPermission('GPATTH')
        }
      ];
      return menu.filter(i => i.permission == true);
    },
    computedSociete() {
      return this.getSettingFilialesTh
        ?.filter(item => item.deleted_at == null && item.type != 'particulier')
        .map(item => {
          return { ...item, checked: false };
        });
    },
    ComputedListInterface: function() {
      return this.getListePermissionToUse.filter(page => {
        if (this.permessionToAdd.page) {
          return (
            this.permessionToAdd.page.findIndex(
              item => item.name === page.name
            ) < 0
          );
        }
      });
    },
    computedListPermission: function() {
      return function(data) {
        let table = this.getListePermission.filter(item => item.name == data);
        return table[0].value;
      };
    },
    computedListProfile: function() {
      return this.roles.map(role => {
        return {
          text: role.name,
          value: role.id,
          disabled: role.blocked == true ? true : false
        };
      });
    }
  },
  components: {
    Card: () => import('../../component/card'),
    MenuUser
  },
  mounted() {
    this.getAllRoles();
    this.getSettingFilialeTh();
    this.getListePermission = permissionModel.create().list;
    this.getListePermissionToUse = permissionModel.create().list;
    this.getAllSocieteAtraiters();
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.multiselect__tag {
  background: #4d4bac;
}

.multiselect__tag-icon:after {
  color: #ffffff;
}

.box-items-permission {
  .box-item-permission {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #4d4bac !important;
      background-color: #4d4bac !important;
    }
  }
}
</style>
