<template>
  <div class="style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="4">
          <div class="title-header">
            Configuration du paiement
          </div>
        </v-col>
        <v-col cols="1">
          <v-progress-circular
            class="ml-2"
            v-if="LoadingReglement || LoadingCondition"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="6"></v-col>
        <v-col cols="1"> </v-col>
      </v-row>
    </div>

    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-if="checkPermission('GTRHTATR')"
                v-show="selectedMenu == 'reglement'"
                @click.prevent="handleModal('type', 'show')"
                class="block-icon-setting text-center mb-1"
                v-on="on"
                v-bind="attrs"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter Type de paiement</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-if="checkPermission('GTRHTACP')"
                v-show="selectedMenu == 'condition'"
                @click.prevent="handleModal('condition', 'show')"
                class="block-icon-setting text-center mb-1"
                v-on="on"
                v-bind="attrs"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span> Ajouter Condition de paiement</span>
          </v-tooltip>
        </div>
      </div>
      <div class="menu-facture-frais btn-actions">
        <div class="list-tabs">
          <v-tabs
            bg-color="#704ad1"
            :hide-slider="false"
            slider-color="#704ad1"
            v-model="model"
          >
            <v-tab
              :href="`#tab-${0}`"
              @click="clickMenu('condition')"
              v-if="checkPermission('GCPHT')"
            >
              <router-link
                to="/setting/configuration-paiement/gestion-conditions-paiement"
                class="font-tabs-facture"
              >
                Gestion des conditions paiements
              </router-link>
            </v-tab>
            <v-tab
              :href="`#tab-${1}`"
              @click="clickMenu('reglement')"
              v-if="checkPermission('GTPHT')"
            >
              <router-link
                to="/setting/configuration-paiement"
                class="font-tabs-facture"
              >
                Gestion des types paiements
              </router-link>
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <div>
        <router-view></router-view>
      </div>
    </div>
    <!--MODAL ADD TYPE PAIEMENT-->
    <v-dialog
      v-model="addTypePaiement"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter Type paiement </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModal('type', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="reglementToAdd.name"
                  :persistent-placeholder="true"
                  :rules="rulesType"
                  :error-messages="errorMessagesType"
                  class="msg-error"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
          <div v-if="errorReglement" class="message-error-modal">
            <ul v-if="Array.isArray(errorReglement)" class="mb-0">
              <li v-for="(e, index) in errorReglement" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ errorReglement }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleSubmitTypeReglement"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click.prevent.stop="handleModal('type', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL ADD CONDITION PAIEMENT-->
    <v-dialog
      v-model="addConditionPaiement"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter condition du paiement </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModal('condition', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="conditionToAdd.name"
                  :persistent-placeholder="true"
                  :rules="rulesName"
                  :error-messages="errorMessagesNom"
                  class="msg-error"
                  ref="condition"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Valeur"
                  v-model="conditionToAdd.value"
                  :persistent-placeholder="true"
                  :rules="rulesValue"
                  :error-messages="errorMessagesValue"
                  class="msg-error"
                  ref="value"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  class="checked-global"
                  v-model="conditionToAdd.fin_du_mois"
                  color="#704ad1"
                  :value="1"
                  :unchecked-value="0"
                  label="Fin du mois"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </form>
          <div v-if="errorConditionPaiement" class="message-error-modal">
            <ul v-if="Array.isArray(errorConditionPaiement)" class="mb-0">
              <li v-for="(e, index) in errorConditionPaiement" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ errorConditionPaiement }}
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleSubmitConditionPaiement"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModal('condition', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      addTypePaiement: false,
      addConditionPaiement: false,
      rulesType: [v => !!v || 'Nom type paiement est obligatoire.'],
      rulesName: [v => !!v || 'Nom condition de paiement est obligatoire.'],
      rulesValue: [v => !!v || 'Valeur condition de paiement est obligatoire.'],
      errorMessagesType: [],
      errorMessagesNom: [],
      errorMessagesValue: [],
      selectedMenu: 'reglement',
      model: 0,
      loading: false,
      error: null,
      conditionToAdd: {
        name: null,
        value: null,
        fin_du_mois: 0
      },
      reglementToAdd: {
        name: null
      }
    };
  },
  methods: {
    ...mapActions([
      'addNewTypeReglement',
      'addNewConditionPaiement',
      'resetMutations',
      'resetMutationsConditions'
    ]),
    async handleSubmitConditionPaiement() {
      if (
        this.$refs.condition.validate() == false ||
        this.$refs.value.validate() == false
      ) {
        this.errorMessagesNom = this.$refs.condition.errorBucket;
        this.errorMessagesValue = this.$refs.value.errorBucket;
        this.loading = false;
      } else {
        // this.conditionToAdd.fin_du_mois =
        //   this.conditionToAdd.fin_du_mois == true ? 1 : 0;
        const response = await this.addNewConditionPaiement(
          this.conditionToAdd
        );
        if (response) {
          this.loading = false;
          this.handleModal('condition', 'hide');
        } else {
          this.loading = false;
        }
      }
    },
    resetModal(type) {
      if (type == 'type') {
        this.$refs.nom.reset();
        this.$refs.nom.errorBucket = [];
        this.errorMessagesType = [];
        this.reglementToAdd = {
          name: null
        };
      } else {
        this.$refs.condition.reset();
        this.$refs.value.reset();
        this.$refs.condition.errorBucket = [];
        this.$refs.value.errorBucket = [];
        this.errorMessagesNom = [];
        this.errorMessagesValue = [];
        this.conditionToAdd = {
          name: null,
          value: null,
          fin_du_mois: 0
        };
      }
    },
    clickMenu(menu) {
      this.selectedMenu = menu;
    },
    handleModal(modal, action) {
      if (modal == 'type') {
        if (action == 'hide') {
          this.addTypePaiement = false;
          this.resetModal('type');
        } else {
          this.addTypePaiement = true;
        }
      }

      if (modal == 'condition') {
        if (action == 'hide') {
          this.addConditionPaiement = false;
          this.resetModal('condition');
        } else {
          this.addConditionPaiement = true;
        }
      }
    },
    async handleSubmitTypeReglement() {
      this.loading = true;
      if (this.$refs.nom.validate() == false) {
        this.errorMessagesType = this.$refs.nom.errorBucket;
        this.loading = false;
      } else {
        const response = await this.addNewTypeReglement(this.reglementToAdd);
        if (response) {
          this.loading = false;
          this.handleModal('type', 'hide');
        } else {
          this.loading = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'errorConditionPaiement',
      'errorValue',
      'errorNameCondition',
      'LoadingCondition',
      'ConditionPaiement',
      'errorNameReglement',
      'errorReglement',
      'LoadingReglement'
    ])
  },
  mounted() {
    if (this.$route.path === '/setting/configuration-paiement') {
      this.selectedMenu = 'reglement';
      this.model = 'tab-1';
    } else {
      this.selectedMenu = 'condition';
      this.model = 'tab-0';
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-actions {
  display: flex;
  justify-content: space-between;
}
.v-application {
  a {
    color: #2f2f2f !important;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 5px;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;
    &.router-link-exact-active {
      color: #704ad1 !important;
      font-size: 11px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      white-space: nowrap;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      text-decoration: none;
      background-color: #f1edfb;
    }
  }
}
.btn-add-setting {
  width: 216px;
  margin-left: 22%;
}
.condition {
  width: 236px !important;
  margin-left: 16% !important;
}
</style>
